<template>
  <div class="text-editor">
    <quill-editor
      ref="myTextEditor"
      :value="value"
      :options="editorOption"
      @change="onEditorChange"
    />
  </div>
</template>

<script>
  import debounce from 'lodash/debounce';
  import {quillEditor } from 'vue-quill-editor'

  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css'

  export default {
    name: 'Editor',
    props: ['value', 'placeholder', 'onChange'],
    components: {
      quillEditor
    },
    data() {
      return {
        editorOption: {
          modules: {
            toolbar: [
              [
                { 'header': [1, 2, 3, 4, 5, 6, false] },
                { 'font': [] }, 
                'bold', 'italic', 'underline', 'strike',
                { 'align': [] }, { 'color': [] }, { 'background': [] }
              ],
              [
                'link', 'image', 'video', 
                { 'list': 'ordered' }, { 'list': 'bullet' }, 
                { 'indent': '-1' }, { 'indent': '+1' }, 
                'blockquote', 'code-block', 
                { 'script': 'sub' }, { 'script': 'super' }, 
                { 'direction': 'rtl' }, 'clean'
              ],
            ],
          },
          placeholder: this.placeholder || 'Insira seu texto aqui...',
          readOnly: false,
          theme: 'snow',
        },
      }
    },
    methods: {
      onEditorChange: debounce(function(value) {
        this.onChange(value.html)
      }, 500),
    },
    computed: {
      editor() {
        return this.$refs.myTextEditor.quill
      },
    },
    mounted() {
      // console.log('this is Quill instance:', this.editor)
    }
  }
</script>

<style lang="scss">
  .text-editor {
    .quill-editor {
      background: #fff;
      border: 1px solid var(--bordercolor);
      border-radius: 15px;
      overflow: hidden;

      .ql-toolbar {
        background: #f5f5f5;
        border-bottom: 1px solid var(--bordercolor) !important;
        border: none;
      }
      
      .ql-container {
        color: #000;
        background: #fff;
        height: 250px;
        border: none;

        .ql-tooltip {
          border: 1px solid var(--bordercolor);
          border-radius: 5px;
          top: 0 !important;
          left: 50% !important;
          transform: translateX(-50%) !important;
          margin-top: 10px !important;

          input {
            border-radius: 0px !important;
            height: 28px !important;
            font-weight: 400 !important;
            font-size: 13px !important;
            color: var(--fontcolor) !important;
          }

          .ql-action{
            &:before {
              content: 'Salvar';
              margin: 0 10px 0 15px;
            }

            &:after {
              display: none !important;
            }
          }
        }
      }
    }
  }
</style>